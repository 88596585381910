import autosize from 'autosize'

$(function () {
  var addDeleteButton, addQuestion, buildQuestionsForWorksheet, clearElements, clearPreviousSelection, clearTable, convertNewQuestion, createElements, createNewQuestionBlock, createTable, deleteButton, deleteQuestion, enableDeleteQuestionClick, enableQuestionTypeChange, enableSaveQuestionClick, getCountFor, hiddenColumnRowCount, howManyChoicesInput, howManyColumnsRows, inlineTextQuestion, multipleQuestion, newCheckbox, newHeadCell, newQuestion, newQuestionWrapper, newRadio, newTableCell, newTableRow, questionIntroduction, questionLabel, questionNumberInput, questionType, questionTypes, renderQuestion, reusableQuestionCheckbox, reusableQuestionName, saveButton, saveQuestion, setColumnRowCount, setupCheckboxes, setupRadioButtons, setupTable, singleQuestion, tableQuestion, textAreaInput, textQuestion, typeInput;
  window.currentQuestionID = 1;
  questionTypes = $('.question__types').clone();
  clearPreviousSelection = function () {
    return $('.question__types').siblings().remove();
  };
  buildQuestionsForWorksheet = function (id) {
    return $.get("/worksheets/" + id + "/questions", function (questions) {
      var j, len, q, type;
      for (j = 0, len = questions.length; j < len; j++) {
        q = questions[j];
        type = (function () {
          switch (q['type']) {
            case 'Text':
              return 'text';
            case 'InlineText':
              return 'inline_text';
            case 'Single':
              return 'single';
            case 'Multiple':
              return 'multiple';
            case 'Table':
              return 'table';
          }
        })();
        addQuestion(type, q);
        saveQuestion();
      }
      $('.question__new').append(saveButton);
      return enableSaveQuestionClick();
    });
  };
  renderQuestion = function () {
    var id, questionType;
    clearPreviousSelection();
    questionType = $(this).val();
    if (questionType.match(/^[0-9]+$/)) {
      id = questionType;
      return $.get("/questions/" + id, function (question) {
        var type;
        type = (function () {
          switch (question['type']) {
            case 'Text':
              return 'text';
            case 'InlineText':
              return 'inline_text';
            case 'Single':
              return 'single';
            case 'Multiple':
              return 'multiple';
            case 'Table':
              return 'table';
          }
        })();
        return addQuestion(type, question);
      });
    } else {
      return addQuestion(questionType);
    }
  };
  addQuestion = function (questionType, question) {
    if (question == null) {
      question = void 0;
    }
    question = newQuestion(questionType, question);
    $('.question__new').append(question);
    return enableSaveQuestionClick();
  };
  newQuestion = function (type, question) {
    var introduction, questionHTML, questionID, sessionNumber, wrapper;
    if (question == null) {
      question = void 0;
    }
    questionID = window.currentQuestionID;
    window.currentQuestionID = questionID + 1;
    wrapper = $('.question__new');
    wrapper.append(questionLabel('intro'));
    if (question) {
      introduction = question.introduction;
    }
    wrapper.append(questionIntroduction(questionID, introduction));
    tinyMCE.init({
      selector: "textarea#question-introduction-" + questionID
    });
    if (!question) {
      sessionNumber = $('#worksheet_session_number').val();
    }
    questionHTML = (function () {
      switch (type) {
        case 'text':
          return textQuestion(questionID, question);
        case 'inline_text':
          return inlineTextQuestion(questionID, question);
        case 'single':
          return singleQuestion(questionID, question);
        case 'multiple':
          return multipleQuestion(questionID, question);
        case 'table':
          return tableQuestion(questionID, question);
      }
    })();
    autosize(questionHTML);
    wrapper.append(questionHTML);
    if (question === void 0) {
      wrapper.append(saveButton);
    }
    $(".js-reuse-question-" + questionID).change(function () {
      if (this.checked) {
        return $(".js-question-" + questionID + "-name").show();
      } else {
        return $(".js-question-" + questionID + "-name").hide();
      }
    });
    return wrapper;
  };
  questionType = function (type) {
    switch (type) {
      case 'text':
        return "Text";
      case 'inline_text':
        return "Fill in the Blank";
      case 'single':
        return "Single Selection";
      case 'multiple':
        return "Multiple Selection";
      case 'table':
        return "Table";
    }
  };
  reusableQuestionName = function (questionID) {
    return "<div class='js-question-" + questionID + "-name input-group hidden'>\n  <label>Question Name</label>\n  <input name='worksheet[questions_attributes][" + questionID + "][name]' size='70' value='Session " + sessionNumber + " Worksheet Reusable " + (questionType(type)) + " Question " + questionID + "' type='text'>\n</div>";
  };
  reusableQuestionCheckbox = function (questionID) {
    return "<div class='checkbox-group'>\n  <label class='checkbox'>\n    <input class='checkbox--hidden js-checkbox-reusable js-reuse-question-" + questionID + "' type='checkbox' value=true name='worksheet[questions_attributes][" + questionID + "][reusable]'>\n    <div class='label--checkbox'>Is this a reusable question? </div>\n  </label>\n</div>";
  };
  questionLabel = function (type) {
    var label;
    label = type === 'intro' ? 'Introduction' : 'Question:';
    return $("<label>" + label + "</label>");
  };
  questionIntroduction = function (questionID, value) {
    if (value == null) {
      value = '';
    }
    return "<textarea name='worksheet[questions_attributes][" + questionID + "][introduction]' placeholder='Enter question notes here' class='tinymce' id='question-introduction-" + questionID + "'> " + value + " </textarea> ";
  };
  textQuestion = function (questionID, question) {
    var questionNumber, text, type;
    if (question == null) {
      question = void 0;
    }
    type = typeInput(questionID, 'QuestionText');
    questionNumber = questionNumberInput(questionID);
    if (question) {
      text = textAreaInput(questionID, question.content[0]);
    } else {
      text = textAreaInput(questionID);
    }
    return $("<br>" + type + text + "<br>" + questionNumber);
  };
  inlineTextQuestion = function (questionID, question) {
    var content, questionNumber, text, type;
    if (question == null) {
      question = void 0;
    }
    type = typeInput(questionID, 'QuestionInlineText');
    questionNumber = questionNumberInput(questionID);
    if (question) {
      content = question['content'][0];
    }
    text = textAreaInput(questionID, content);
    return $("<br> " + type + text + " <label> NOTE: Type %box% in your question above to add a fill in the blank input box for participants to use. </label> <br> " + questionNumber);
  };
  newRadio = function (questionID, index, value) {
    if (value == null) {
      value = '';
    }
    questionID || (questionID = $('.question').size() + 1);
    return "<input id='question_" + questionID + "_" + index + "' name='worksheet[questions_attributes][" + questionID + "][content][]' value='" + value + "' class='question__radio-label' placeholder='Enter choice here...' type='text' />";
  };
  newCheckbox = function (questionID, index, value) {
    if (value == null) {
      value = '';
    }
    questionID || (questionID = $('.question').size() + 1);
    return "<input id='question_" + questionID + "_" + index + "' name='worksheet[questions_attributes][" + questionID + "][content][]' value='" + value + "' class='question_checkbox-label' placeholder='Enter choice here...' type='text' />";
  };
  newHeadCell = function (questionID, index, value) {
    if (value == null) {
      value = '';
    }
    questionID || (questionID = $('.question').size() + 1);
    return "<th><input id='head_cell_" + questionID + "_" + index + "' name='worksheet[questions_attributes][" + questionID + "][content][]' value='" + value + "' placeholder='Header text...'/></th>";
  };
  newTableCell = function (index, rowIndex, questionID, value) {
    if (value == null) {
      value = '';
    }
    if (index === 0) {
      return "<td><input id='row_cell_" + questionID + "_" + rowIndex + "' name='worksheet[questions_attributes][" + questionID + "][content][]' value='" + value + "'/></td>";
    } else {
      return "<td><input name='worksheet[questions_attributes][" + questionID + "][content][]' disabled=true /></td>";
    }
  };
  newTableRow = function (questionID, rowIndex, cols, value) {
    var cells;
    questionID || (questionID = $('.question').size() + 1);
    cells = $.map(_.range(cols), function (i) {
      return newTableCell(i, rowIndex, questionID, value);
    }).join('');
    return "<tr>" + cells + "</tr>";
  };
  clearElements = function (wrapperName) {
    return $(this).siblings("." + wrapperName).remove();
  };
  clearTable = function () {
    return $(this).siblings('.js-table-wrapper').remove();
  };
  createElements = function (wrapper_class, newElement) {
    var elements, howMany, wrapper;
    howMany = $(this).val();
    wrapper = $("<div class='" + wrapper_class + "'>");
    elements = $.map(_.range(howMany), function (index) {
      return newElement(index);
    }).join('<br>');
    wrapper.append(elements);
    return $(wrapper).insertBefore($(this).siblings('.js-question-number-label'));
  };
  getCountFor = function (element) {
    if ($(this).hasClass("js-" + element + "-input")) {
      return $(this).val();
    } else {
      return $(this).siblings(".js-" + element + "-input").val();
    }
  };
  createTable = function (questionID, cols, rows, values) {
    var body, columnValues, header, rowValues, table, wrapper;
    if (values == null) {
      values = void 0;
    }
    if (values != null) {
      columnValues = values.slice(0, cols);
      rowValues = values.slice(cols);
    } else {
      columnValues = [];
      rowValues = [];
    }
    wrapper = $('<div class="js-table-wrapper">');
    table = $('<table class="table"></table>');
    header = $('<thead></thead>');
    body = $('<tbody></tbody>');
    header.append($.map(_.range(cols), function (index) {
      return newHeadCell(questionID, index, columnValues[index]);
    }).join(''));
    body.append($.map(_.range(rows), function (index) {
      return newTableRow(questionID, index, cols, rowValues[index]);
    }).join(''));
    table.append(header);
    table.append(body);
    wrapper.append(table);
    wrapper.append('<br />');
    $(wrapper).insertBefore($(this).siblings('.js-question-number-label'));
    return wrapper;
  };
  setupRadioButtons = function (questionID) {
    var wrapperName;
    wrapperName = 'js-radio-wrapper';
    clearElements.call(this, wrapperName);
    return createElements.call(this, wrapperName, (function (_this) {
      return function (index) {
        return newRadio(questionID, index);
      };
    })(this));
  };
  setupCheckboxes = function (questionID) {
    var wrapperName;
    wrapperName = 'js-checkboxes-wrapper';
    clearElements.call(this, wrapperName);
    return createElements.call(this, wrapperName, (function (_this) {
      return function (index) {
        return newCheckbox(questionID, index);
      };
    })(this));
  };
  setColumnRowCount = function (cols, rows) {
    return $(this).siblings('.js-column-row-count').val(cols + "_" + rows);
  };
  setupTable = function () {
    var cols, questionID, rows;
    cols = getCountFor.call(this, 'column');
    rows = getCountFor.call(this, 'row');
    questionID = $(this).data('count');
    clearTable.call(this);
    setColumnRowCount.call(this, cols, rows);
    return createTable.call(this, questionID, cols, rows);
  };
  howManyChoicesInput = function (onChange, questionID, value) {
    var input, label;
    if (value == null) {
      value = '';
    }
    label = "<label>How many choices does the question have?</label>";
    input = "<input id='question_" + questionID + "' data-question-id='" + questionID + "' class='question__number-input js-how-many' type='number' value='" + value + "' />";
    $('.question__new').off('change');
    $('.question__new').on('change', '.js-how-many', function () {
      return onChange.call(this, questionID);
    });
    return "" + label + input;
  };
  howManyColumnsRows = function (questionID, question) {
    var columnInput, columnLabel, columnValue, rowInput, rowLabel, rowValue;
    columnLabel = "<label>How many columns?</label>";
    columnValue = question ? _.split(question['content'][0], "_")[0] : '';
    columnInput = "<input id='question_" + questionID + "_column_input' class='question__number-input js-column-input' data-count='" + questionID + "' type='number' value='" + columnValue + "' />";
    rowLabel = "<label>How many rows?</label>";
    rowValue = question ? _.split(question['content'][0], "_")[1] : '';
    rowInput = "<input id='question_" + questionID + "_row_input' class='question__number-input js-row-input' data-count='" + questionID + "' type='number' value='" + rowValue + "' />";
    $('.question__new').off('change');
    $('.question__new').on('change', '.js-column-input', setupTable);
    $('.question__new').on('change', '.js-row-input', setupTable);
    return "" + columnLabel + columnInput + "<br>" + rowLabel + rowInput;
  };
  hiddenColumnRowCount = function (questionID) {
    return "<input class='js-column-row-count' type='hidden' name='worksheet[questions_attributes][" + questionID + "][content][]' />";
  };
  singleQuestion = function (questionID, question) {
    var choiceText, content, contentLength, questionNumber, radios, text, type;
    if (question == null) {
      question = void 0;
    }
    type = typeInput(questionID, 'QuestionSingle');
    questionNumber = questionNumberInput(questionID);
    if (question) {
      content = question['content'][0];
    }
    text = textAreaInput(questionID, content);
    if (question != null) {
      contentLength = question['content'].length;
      radios = $("<div class='js-radio-wrapper'>");
      $.each(question['content'].slice(1, +contentLength + 1 || 9e9), function (index, value) {
        return radios.append(newRadio(questionID, index, value) + "<br>");
      });
      choiceText = howManyChoicesInput(setupRadioButtons, questionID, contentLength - 1);
      return $("<br>" + type + text + "<br>" + choiceText + "<br>" + radios[0].outerHTML + "<br>" + questionNumber);
    } else {
      choiceText = howManyChoicesInput(setupRadioButtons, questionID);
      return $("<br>" + type + text + "<br>" + choiceText + "<br>" + questionNumber);
    }
  };
  multipleQuestion = function (questionID, question) {
    var answerOptions, checkboxes, choiceText, content, contentLength, questionNumber, text, type;
    if (question == null) {
      question = void 0;
    }
    type = typeInput(questionID, 'QuestionMultiple');
    questionNumber = questionNumberInput(questionID);
    if (question) {
      content = question['content'][0];
    }
    text = textAreaInput(questionID, content);
    if (question != null) {
      contentLength = question['content'].length;
      answerOptions = '';
      checkboxes = $("<div class='js-checkboxes-wrapper'>");
      $.each(question['content'].slice(1, +contentLength + 1 || 9e9), function (index, value) {
        return checkboxes.append(newCheckbox(questionID, index, value) + "<br>");
      });
      choiceText = howManyChoicesInput(setupCheckboxes, questionID, contentLength - 1);
      return $("<br>" + type + text + "<br>" + choiceText + "<br>" + checkboxes[0].outerHTML + "<br>" + questionNumber);
    } else {
      choiceText = howManyChoicesInput(setupCheckboxes, questionID);
      return $("<br>" + type + text + "<br>" + choiceText + "<br>" + questionNumber);
    }
  };
  tableQuestion = function (questionID, question) {
    var columnRowCount, columnRowCountElem, columns, questionNumber, r_c, rows, table, tableChoiceText, type;
    if (question == null) {
      question = void 0;
    }
    type = typeInput(questionID, 'QuestionTable');
    questionNumber = questionNumberInput(questionID);
    columnRowCountElem = hiddenColumnRowCount(questionID);
    tableChoiceText = howManyColumnsRows(questionID, question);
    if (question != null) {
      r_c = _.split(question['content'][0], "_");
      rows = r_c[0];
      columns = r_c[1];
      table = $("<div class='js-table-wrapper'>");
      columnRowCount = $(columnRowCountElem).val(r_c[0] + "_" + r_c[1]);
      table.append(createTable(questionID, rows, columns, question['content'].slice(1, +question.content.length + 1 || 9e9)));
      return $("<br>" + type + "<br>" + tableChoiceText + columnRowCount[0].outerHTML + "<br>" + questionNumber + table[0].outerHTML);
    } else {
      return $("<br>" + type + "<br>" + tableChoiceText + columnRowCountElem + "<br>" + questionNumber);
    }
  };
  textAreaInput = function (questionID, value) {
    if (value == null) {
      value = '';
    }
    return "<textarea name='worksheet[questions_attributes][" + questionID + "][content][]' placeholder='Enter question here'> " + value + " </textarea>";
  };
  typeInput = function (questionID, type) {
    return "<input type='hidden' name='worksheet[questions_attributes][" + questionID + "][type]' value='" + type + "' />";
  };
  questionNumberInput = function (questionID) {
    return "<label class='js-question-number-label'>Question number: </label> <input name='worksheet[questions_attributes][" + questionID + "][question_number]' class='question__number-input' value='" + questionID + "' type='number' />";
  };
  deleteQuestion = function () {
    return $(this).parent('.question').remove();
  };
  saveButton = function () {
    return $("<br><button class='button button--alt-light-green button--small js-save-question'>Add Question to Worksheet</button>");
  };
  deleteButton = function () {
    return $("<a class='js-delete-question question__new__delete'>Delete Question</a>");
  };
  newQuestionWrapper = function () {
    return $("<div class='question__new'>");
  };
  addDeleteButton = function () {
    $('.question__new').append(deleteButton);
    return enableDeleteQuestionClick();
  };
  convertNewQuestion = function () {
    $('.question__types').remove();
    addDeleteButton();
    $('.question__new').removeClass('question__new').addClass('question');
    return $(this).remove();
  };
  createNewQuestionBlock = function () {
    var wrapper;
    wrapper = newQuestionWrapper();
    wrapper.append(questionTypes);
    $('.questions').append(wrapper);
    $('.js-question-types').val('');
    return enableQuestionTypeChange();
  };
  saveQuestion = function () {
    convertNewQuestion.call(this);
    return createNewQuestionBlock();
  };
  enableQuestionTypeChange = function () {
    return $('.js-question-types').change(renderQuestion);
  };
  enableSaveQuestionClick = function () {
    return $('.js-save-question').click(saveQuestion);
  };
  enableDeleteQuestionClick = function () {
    return $('.js-delete-question').click(deleteQuestion);
  };
  enableQuestionTypeChange();
  if ($('#edit-worksheet-form').length > 0) {
    return buildQuestionsForWorksheet($('.edit_worksheet').data('id'));
  }
});