$(function () {
  $('.js-table').DataTable({
    aaSorting: []
  });

  $('#registered-participants-table').DataTable({
    info: true,
    searching: true,
    lengthMenu: [ [ 25, 50, 100, -1 ], [ 25, 50, 100, 'All' ] ],
    pageLength: 25,
    dom: '<lf<t>ip>',
    columnDefs: [
      {
        'targets': [ 3, 4, 5 ],
        'orderable': false
      }
    ],
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ Participants"
    }
  });

  $('#pending-participants-table').DataTable({
    info: true,
    searching: true,
    lengthMenu: [ [ 25, 50, 100, -1 ], [ 25, 50, 100, 'All' ] ],
    pageLength: 25,
    dom: '<lf<t>ip>',
    columnDefs: [
      {
        'targets': [ 2 ],
        'orderable': false
      }
    ],
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ Participants"
    }
  });

  $('#inactive-participants-table').DataTable({
    info: true,
    searching: true,
    lengthMenu: [ [ 25, 50, 100, -1 ], [ 25, 50, 100, 'All' ] ],
    pageLength: 25,
    dom: '<lf<t>ip>',
    columnDefs: [
      {
        'targets': [ 2, 4 ],
        'orderable': false
      }
    ],
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ Participants"
    }
  });


  $('#coach-appointments-table').DataTable({
    info: true,
    searching: true,
    pageLength: 25,
    dom: '<<t>fip>',
    columnDefs: [
      {
        'targets': [3, 7],
        'orderable': false
      }
    ],
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ Appointments"
    }
  });
  $('#coach-participants-table').DataTable({
    info: true,
    searching: true,
    dom: '<f<t>ip>',
    columnDefs: [
      {
        'targets': [ 2, 3, 4 ],
        'orderable': false
      }
    ],
    order: [[1, 'asc']],
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ Participants"
    }
  });
  $('.js-calls-table').on('click', '.js-toggle-accordion', function () {
    $(this).siblings('.note').toggleClass('open');
    return $(this).toggleClass('fa-minus fa-plus');
  });
  $('#js-users-appointments-table').DataTable({
    info: true,
    order: [[2, 'desc']],
    searching: true,
    dom: '<f<t>ip>',
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ Appointments"
    }
  });
  return $('#participant-foods-index-table').DataTable({
    info: true,
    searching: true,
    pageLength: 25,
    dom: '<f<t>ip>',
    columnDefs: [
      {
        'targets': 5,
        'orderable': true
      }
    ],
    order: [[4, 'desc']],
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search",
      info: "Showing _START_ to _END_ of _TOTAL_ Foods"
    }
  });
});
