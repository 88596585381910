class Header {
  static onCall = () => {
    console.log('Header.onCall()')
    $('.header').addClass('header--on-call');
    $('.header__call-action .button').removeClass('button--red');
    $('.header__call-action .button').addClass('button--white');
  }

  static onHangUp = () => {
    console.log('Header.onHangup()')
    $('.header').removeClass('header--on-call');
    $('.header__call-action .button').addClass('button--red');
    $('.header__call-action .button').removeClass('button--white');
  }
}

export default Header;