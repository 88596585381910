$(function () {
  return $.extend($.fn.dataTable.defaults, {
    searching: false,
    conditionalPagination: true,
    pageLength: 20,
    lengthChange: false,
    info: false,
    "fnDrawCallback": function (oSettings) {
      var $paginate;
      $paginate = this.siblings('.dataTables_paginate');
      if (this.api().data().length <= this.fnSettings()._iDisplayLength) {
        return $paginate.hide();
      } else {
        return $paginate.show();
      }
    }
  });
});