import FormHelpers from './formHelpers';

$(function () {
  var forms;
  // forms = ['.js-weight-form', '.js-activity-form', '.js-foods-form'];
  forms = ['.js-activity-form' ];
  $('.js-close-modal').click(function () {
    var form, formEl, i, inputs, len;
    for (i = 0, len = forms.length; i < len; i++) {
      form = forms[i];
      if ($(this).parents("" + form).length > 0) {
        formEl = $(this).parents("" + form);
        break;
      } else {
        formEl = null;
      }
    }
    if (formEl) {
      inputs = formEl.find("input");
      FormHelpers.clearErrors(formEl);
    }
  });
});
