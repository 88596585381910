import moment from 'moment'

$(function () {
  var dayOfWeek, roundTime;
  roundTime = function () {
    var now;
    now = moment();
    if (now.minutes() < 30) {
      now.startOf('hour');
    } else {
      now.startOf('hour').add(60, 'minutes');
    }
    return now.format('h:mm A');
  };
  dayOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  $('.js-datetimepicker').datetimepicker({
    step: 30,
    format: 'm/d/Y h:i A',
    formatTime: 'h:i A',
    defaultTime: roundTime(),
    validateOnBlur: false,
    className: 'bwel-datepicker',
    todayButton: false,
    i18n: {
      en: {
        dayOfWeek: dayOfWeek
      }
    }
  });
  $('.js-datepicker').datetimepicker({
    validateOnBlur: false,
    timepicker: false,
    value: Date(),
    maxDate: Date(),
    format: 'm/d/Y',
    className: 'bwel-datepicker',
    todayButton: false,
    i18n: {
      en: {
        dayOfWeek: dayOfWeek
      }
    }
  });
  $('.js-datepicker.food').val('');
  $('.js-datepicker-export').datetimepicker({
    validateOnBlur: false,
    timepicker: false,
    format: 'm/d/Y',
    className: 'bwel-datepicker',
    i18n: {
      en: {
        dayOfWeek: dayOfWeek
      }
    }
  });
  $('.js-inline-datepicker').datetimepicker({
    validateOnBlur: false,
    format: 'Y-m-d',
    className: 'bwel-inline-datepicker',
    inline: true,
    scrollMonth: false,
    todayButton: false,
    timepicker: false,
    i18n: {
      en: {
        dayOfWeek: dayOfWeek
      }
    },
    onSelectDate: function (date) {
      return $('.js-fullcalendar').fullCalendar('gotoDate', date);
    }
  });
  $('.js-timepicker').datetimepicker({
    validateOnBlur: false,
    datepicker: false,
    className: 'bwel-timepicker',
    format: 'H:i'
  });
  return $('.js-ends-on-datepicker').datetimepicker({
    validateOnBlur: false,
    format: 'Y-m-d',
    className: 'bwel-datepicker',
    todayButton: false,
    timepicker: false,
    i18n: {
      en: {
        dayOfWeek: dayOfWeek
      }
    }
  });
});