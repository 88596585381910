import moment from 'moment';
import Highcharts from 'highcharts';
import '../charts/default';
import { deleteWeight } from '../../pages/participants/index/weight'

const WeightOptions = {
  initWeightChart: function ($weightChart) {
    var goal, options, participantId, participantMaxWeight, participantMinWeight, series, weightChart, yMax, yMin;
    weightChart = $('.js-weight-chart');
    participantId = weightChart.data('participant_id');
    goal = weightChart.data('goal');
    participantMinWeight = weightChart.data('participant_min_weight');
    participantMaxWeight = weightChart.data('participant_max_weight');
    yMin = participantMinWeight * 0.985;
    yMax = participantMaxWeight * 1.025;
    series = {
      data: []
    };
    options = {
      chart: {
        renderTo: $weightChart[0],
        type: 'line'
      },
      plotOptions: {
        series: {
          turboThreshold: 5000,
          cursor: 'pointer',
          point: {
            events: {
              click: function (event) {
                deleteWeight(event.point.id);
              }
            }
          }
        }
      },
      yAxis: {
        min: yMin,
        max: yMax,
        title: {
          text: 'Weight (lbs)'
        },
        tickInterval: 5,
        plotLines: [
          {
            value: goal,
            label: {
              text: "Goal: " + goal + "lbs",
              textAlign: 'left'
            },
            color: 'red',
            width: 2,
            dashStyle: 'shortdash'
          }
        ]
      },
      xAxis: {
        title: {
          text: 'Date'
        }
      },
      series: [],
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          return ("<div class='tooltip-x'>" + (moment(this.point.x).format('MMM DD, YYYY')) + "</div>") + ("<div class='tooltip-y'>" + this.point.y + " lbs</div>");
        }
      }
    };

    $.ajax({
      url: '/weights',
      type: 'GET',
      async: true,
      dataType: 'json',
      data: {
        participant_id: participantId
      },
      success: function (weights) {
        _.each(weights, function (weight) {
          var date, utc;
          date = moment(weight.date_weighed);
          utc = date.valueOf();
          series.data.push({
            x: utc,
            y: weight.weight,
            id: weight.id
          });
        });
        options.series.push(series);
        return new Highcharts.Chart(options);
      }
    })
  }
};

export default WeightOptions;
