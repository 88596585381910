var bind = function (fn, me) { return function () { return fn.apply(me, arguments); }; },
  slice = [].slice;

$(() => {
  const BwelLabel = () => {
    var getLabel;

    BwelLabel.prototype.defaults = {
      classNames: {
        LABEL_WITH_VALUE: 'label--with-value'
      }
    };

    function BwelLabel(el, options) {
      this.open = bind(this.open, this);
      this.close = bind(this.close, this);
      this.onChange = bind(this.onChange, this);
      this.init = bind(this.init, this);
      this.options = $.extend({}, this.defaults, options);
      this.$el = $(el);
      this.init();
    }

    BwelLabel.prototype.init = function () {
      this.$el.on('focus', this.open);
      this.$el.on('change', this.onChange);
      return this.onChange();
    };

    BwelLabel.prototype.onChange = function () {
      this.$label = getLabel(this.$el);
      this.hasValue = !!this.$el.val();
      if (this.hasValue) {
        return this.open();
      } else {
        return this.close();
      }
    };

    BwelLabel.prototype.close = function () {
      var hasValue;
      hasValue = !!this.$el.val();
      if (!this.hasValue) {
        return this.$label.removeClass(this.options.classNames.LABEL_WITH_VALUE);
      }
    };

    BwelLabel.prototype.open = function () {
      this.$label.addClass(this.options.classNames.LABEL_WITH_VALUE);
      return false;
    };

    getLabel = function (el) {
      var label;
      return label = $(el).siblings('label');
    };

    return BwelLabel;
  }
  return $.fn.extend({
    bwelLabel: function () {
      var args, option;
      option = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      return this.each(function () {
        var $this, data;
        $this = $(this);
        data = $this.data('bwelLabel');
        if (!data) {
          $this.data('bwelLabel', (data = new BwelLabel(this, option)));
        }
      });
    }
  });
});
