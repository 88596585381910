let bind = function (fn, me) { return function () { return fn.apply(me, arguments); }; },
  slice = [].slice;

let BwelModal;
$(() => {
  BwelModal = (function () {
    BwelModal.prototype.defaults = {
      classNames: {
        MODAL_CLOSE: '.js-close-modal',
        BODY_CONTAINS_MODAL: 'body-contains-modal'
      }
    };

    function BwelModal($el, options) {
      this.close = bind(this.close, this);
      this.open = bind(this.open, this);
      var autoOpen;
      this.options = $.extend({}, this.defaults, options);
      this.$el = $el;
      autoOpen = this.$el.data('auto-open');
      $("" + this.options.classNames.MODAL_CLOSE).on('click', (function (_this) {
        return function () {
          return _this.close();
        };
      })(this));
      if (autoOpen) {
        this.open();
      } else {
        this.$el.hide();
      }
    }

    BwelModal.prototype.open = function (callback) {
      $(this.$el).fadeIn('fast');
      $('body').addClass(this.options.classNames.BODY_CONTAINS_MODAL);
      if (callback) {
        return callback();
      }
    };

    BwelModal.prototype.close = function () {
      $(this.$el).fadeOut('fast');
      return $('body').removeClass(this.options.classNames.BODY_CONTAINS_MODAL);
    };

    return BwelModal;

  })();
  return $.fn.extend({
    bwelModal: function () {
      var args, option;
      option = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      return this.each(function () {
        var $this, data;
        $this = $(this);
        data = $this.data('bwelModal');
        if (!data) {
          $this.data('bwelModal', (data = new BwelModal($this, option)));
        }
        if (typeof option === 'string') {
          return data[option].apply(data, args);
        }
      });
    }
  });
})

export default BwelModal;