import FormHelpers from '../forms/formHelpers';
import '../../plugins/bwelModal';

let $scheduleForm, clearErrors, clearFieldError, closeModal, fields;
$(function () {
  fields = {
    repeat_type: function () {
      var checkedRadios;
      checkedRadios = $('.js-repeat-type input:checked');
      if (!checkedRadios.length) {
        return 'No repeat type selected';
      }
    },
    repeat_every: function () {
      var selector;
      selector = $('.js-repeat-select');
      if (!(selector.val() || selector.val() === 'Select an interval')) {
        return 'No repeat interval selected';
      }
    },
    end_date: function () {
      var selector;
      selector = $('.js-ends-on-datepicker');
      if (!selector.val()) {
        return 'No date selected';
      }
    }
  };
  $scheduleForm = $('#scheduleForm');
  clearFieldError = function (field) {
    return $(field).parents('.field').removeClass('input--error');
  };
  clearErrors = function () {
    $scheduleForm.find('input').each(function (index, input) {
      return clearFieldError(input);
    });
    return $('.schedule-error-info').html('');
  };
  closeModal = function () {
    $('.js-schedule-call-modal').bwelModal('close');
    $scheduleForm[0].reset();
    return $scheduleForm.find('.input--error').removeClass('input--error');
  };
  $scheduleForm.on("ajax:before", function () {
    var errors;
    clearErrors();
    if ($('.js-set-recurring:checked').val() === 'true') {
      errors = Object.keys(fields).reduce((function (memo, field) {
        var result;
        result = fields[field]();
        if (result) {
          memo[field] = result;
        }
        return memo;
      }), {});
      if (Object.keys(errors).length) {
        FormHelpers.handleErrors($scheduleForm, 'appointment', errors);
        return false;
      }
    }
  });
  $scheduleForm.on("ajax:success", function () {
    $('.js-fullcalendar').fullCalendar('refetchEvents');
    return closeModal();
  });
  $scheduleForm.on("ajax:error", function (xhr, status, error) {
    var key, response, results;
    response = JSON.parse(status.responseText);
    results = [];
    for (key in response) {
      if (key === 'participant_id') {
        $scheduleForm.find("select[name='appointment[" + key + "]']").parents('.field').addClass('input--error');
      } else {
        $scheduleForm.find("input[name='appointment[" + key + "]']").parents('.field').addClass('input--error');
      }
      if (key === 'start_time') {
        $('div.schedule__set-ignore-conflicts').removeClass('hidden');
        results.push($('.schedule-error-info').html(response[key]));
      } else {
        results.push(void 0);
      }
    }
    return results;
  });
  $('.js-open-schedule-call-modal').click(function () {
    return $('.js-repeat-picker').hide();
  });
  $('.js-schedule-call-modal .js-close-modal').click(closeModal);
  $('.js-repeat-picker input, .js-repeat-picker select').change(function (event) {
    return clearFieldError(event.target);
  });
  return $('.js-repeat-type input').change(function () {
    return clearFieldError('.js-repeat-select');
  });
});

export default { $scheduleForm, clearErrors, clearFieldError, closeModal, fields };