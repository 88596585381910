$(function() {
  $("body").on("click", hideNavMenu);
  $(".dropdown").on("click", toggleNavMenu);
});

function toggleNavMenu(event) {
  $(".dropdown").toggleClass('active');
  event.stopPropagation();
}

function hideNavMenu(event) {
  if ($(this).prop("tagName") === "BODY") {
    $(".dropdown").removeClass('active');
  }
}