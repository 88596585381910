import FormHelpers from '../../../components/forms/formHelpers';
import WeightOptions from '../../../components/weights/weight_options';
import '../../../plugins/bwelModal'

let $chart, posNegFormatter, updateValues, deleteWeight;

$(() => {
  $chart = $('.js-weight-chart');
  $('.js-open-weight-modal').on('click', function () {
    $('.js-weight-modal').bwelModal('open');
    $('div.activity__main-action')[3].innerText = $('.activity__main-action')[2].innerText
    $('input#weight_weight')[0].value = ''

    let newDate = new Date();
    let newDateMonth = newDate.getMonth() + 1;
    let newDateString = newDateMonth + '/' + newDate.getDate() + '/' + newDate.getFullYear();
    $('input#weight_date_weighed')[0].value = newDateString
  });

  posNegFormatter = function (number) {
    if (number > 0) {
      return "-" + number;
    } else if (number < 0) {
      return "+" + (Math.abs(number));
    } else {
      return "" + number;
    }
  };

  updateValues = (data) => {
    $('.activity--weight .activity__main-action').html(posNegFormatter(data.weight_lost));
    $('.activity--weight .current_weight').html(data.current_weight);
    $('.activity--weight .last_weigh_in').html(data.last_weigh_in);
    $('.js-weight-chart').data('participant_min_weight', data.weight_graph_min);
    $('.js-weight-chart').data('participant_max_weight', data.weight_graph_max);
    $('#js-modal-weight').text(data.current_weight);
    $('#js-modal-weight-date').text(data.last_weigh_in);
  };

  $('.js-weight-form').on('submit', (event) => {
    event.preventDefault()
    let formValues = $('.js-weight-form').serializeArray();
    let dataObject = {
      date_weighed: formValues[1].value,
      weight: formValues[2].value,
      participant_id: formValues[3].value,
    }

    $.ajax({
      url: '/weights',
      dataType: 'json',
      method: 'POST',
      data: { weight: dataObject }
    }).done(response => {
      updateValues(response);
      $('.js-status').bwelStatusMessage('open', "Weight was logged successfully!");
      $('.js-weight-modal').bwelModal('close');
      WeightOptions.initWeightChart($chart);
    })
    return false
  })

  $('.js-weight-form').on('ajax:error', function (xhr, status) {
    if (status.status === 403) {
      return FormHelpers.handleUnauthorized(this, 'Weight');
    } else {
      return FormHelpers.handleErrors(this, 'weight', status.responseJSON);
    }
  });

  deleteWeight = (id) => {
    $('.js-delete-single-weight-modal').bwelModal('open');
    $('a.button.text-small.js-delete-single-weight').on('click', () => {

      if (id) {
        $.ajax({
          url: "/weights/" + id,
          dataType: 'json',
          type: 'DELETE'
        }).done(response => {
          updateValues(response);
          $('.js-delete-single-weight-modal').bwelModal('close');
          WeightOptions.initWeightChart($chart);
          id = null
        })
      }
    })
  };

  if ($chart.length > 0) {
    WeightOptions.initWeightChart($chart);
  }
});

export { deleteWeight };