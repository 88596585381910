$(() => {
  toggle()
})

const toggle = () => {
  $('.js-accordion').on('click', (event) => {
    const section = event.currentTarget
    const sectionParent = section.parentElement
    const sectionParentClasslist = Array.from(sectionParent.classList)
    if (sectionParentClasslist.includes('section--closed')) {
      open(section)
    } else {
      close(section)
    }
  })
}

const open = (section) => {
  section.parentElement.classList.add('section--open')
  section.parentElement.classList.remove('section--closed')
  section.parentElement.nextElementSibling.style['display'] = ''
}

const close = (section) => {
  section.parentElement.classList.remove('section--open')
  section.parentElement.classList.add('section--closed')
  section.parentElement.nextElementSibling.style['display'] = 'none'
}