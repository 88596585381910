import WeightOptions from '../../../components/weights/weight_options';
import '../../../plugins/bwelStatusMessage'
import '../../../plugins/bwelModal'
import '../../../components/calendar/fullcalendar'

let $callsTable, $weightChart, $notesTable, options, reassignTable, updateCoach, updateGoals, coachId, participantId, atRisk;

$(() => {
  $weightChart = $('.js-weight-chart');
  options = {
    searching: true
  };
  reassignTable = $('.js-reassign-table').DataTable(options);
  $('#registered-participants-table').on('click', '.js-open-reassign-modal', (event) => {
    event.preventDefault();
    participantId = event.currentTarget.dataset.participantId
    coachId = event.currentTarget.dataset.coachId
    $('.js-reassign-modal').bwelModal('open');
  });
  $('.js-search-participants').on('keyup', function () {
    return reassignTable.columns(0).search(this.value).draw();
  });

  $('.js-at-risk').on('click', (event) => {
    participantId = event.currentTarget.dataset.participantId
    atRisk = event.target.checked

    $.ajax({
      headers: {
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content'),
      },
      url: "/participants/" + participantId + ".json",
      method: "PUT",
      data: {
        "participant": {
          "at_risk": atRisk
        }
      },
      success: (response) => {
        if (response["at_risk"]) {
          $('.js-status').bwelStatusMessage('open', 'Participant is "at risk" of dropping out of the study.')
        } else if (!response["at_risk"]) {
          $('.js-status').bwelStatusMessage('open', 'Participant is NOT "at risk" of dropping out of the study.')
        }
      }
    })
  });

  $('.js-assign-coach').on('click', (event) => {
    event.preventDefault();
    coachId = event.currentTarget.dataset.coachId

    $.ajax({
      headers: {
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content'),
      },
      url: "/participants/" + participantId + ".json",
      method: 'PUT',
      data: {
        "participant": {
          "coach_id": coachId
        }
      },
      success: function (data) {
        return updateCoach(coachId, participantId);
      }
    });
  });

  updateCoach = function (coachId, participantId) {
    $.get("/coaches/" + coachId + ".json", function (data) {

      var cell;
      cell = $("td [data-participant-id=" + participantId + "]").parent();
      cell.siblings('.js-coach-name').html("<a href='coaches/" + coachId + "'>" + data.first_name + " " + data.last_name + "</a>");
      $('.js-reassign-modal').bwelModal('close');
      return $('.js-status').bwelStatusMessage('open', 'The participant was successfully reassigned!');
    });
  };
  $('.js-open-schedule-call-modal').on('click', (event) => {
    event.preventDefault();

    $('.js-schedule-call-modal').bwelModal('open');
    return $('.js-fullcalendar').fullCalendar('render');
  });
  options = {
    columnDefs: [
      {
        targets: 0,
        orderable: true,
        type: 'date'
      }, {
        targets: 1,
        orderable: false
      }, {
        targets: 2,
        orderable: false
      }
    ],
    pagingType: "simple",
    pageLength: 5,
    order: [[0, "desc"]]
  };
  $callsTable = $('.js-calls-table');
  $callsTable.DataTable(options);
  $notesTable = $('.js-notes-table');
  options = {
    columnDefs: [
      {
        targets: 0,
        orderable: true
      }, {
        targets: 1,
        orderable: true
      }, {
        targets: 2,
        orderable: true,
        type: 'date'
      }
    ],
    pagingType: "simple_numbers",
    pageLength: 4,
    order: [[2, "desc"]]
  };
  $notesTable.DataTable(options);
  updateGoals = function () {
    var obj, params;
    participantId = $(this).data('participant-id');
    params = {
      participant: (
        obj = {},
        obj["" + this.id] = this.value,
        obj
      )
    };

    $.ajax({
      headers: {
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content'),
      },
      url: "/participants/" + participantId,
      type: 'PUT',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(params),
      success: function (data) {
        $('.js-status').bwelStatusMessage('open', 'The goal was successfully saved!');
        $('.js-weight-chart').data('goal', data.weight_goal);
        return WeightOptions.initWeightChart($weightChart);
      },
      error: function () {
        return $('.js-status').bwelStatusMessage('open', 'The goal was not successfully saved.');
      }
    });
  };
  $('#weight_goal').change(updateGoals);
  return $('#activity_goal').change(updateGoals);
});

export { $callsTable, $weightChart, $notesTable, options, reassignTable, updateCoach, updateGoals }
