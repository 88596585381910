import autosize from 'autosize'

$(function () {
  var $body, $questions, $questionsBreadcrumb, $questionsButton, $review, $reviewBreadcrumb, $saveWorksheetButton, setOpenWorksheetClick, setUpWorksheetTable, setUpWorksheetWizard, showWorksheetQuestions, showWorksheetReview, sizeTableTextareas, updateFilename;
  $body = $('body');
  $review = $('.worksheet-review');
  $reviewBreadcrumb = $('.js-review-breadcrumb');
  $questions = $('.questions');
  $questionsBreadcrumb = $('.js-questions-breadcrumb');
  $questionsButton = $('.js-questions-button');
  $saveWorksheetButton = $('.js-save-answers');
  setOpenWorksheetClick = function () {
    return $('.js-open-worksheet-modal').click(initWorksheetModal);
  };
  setUpWorksheetWizard = function () {
    $reviewBreadcrumb.click(showWorksheetReview);
    return $questionsBreadcrumb.add($questionsButton).click(showWorksheetQuestions);
  };
  setUpWorksheetTable = function () {
    var $table, dataTableOptions;
    $table = $('.js-worksheet-table');
    dataTableOptions = {
      draw: setOpenWorksheetClick
    };
    if ($table.data('dt-sort-order') && $table.data('dt-sort-column')) {
      dataTableOptions.order = [[$table.data('dt-sort-column') - 1, $table.data('dt-sort-order')]];
    }
    return $table.DataTable(dataTableOptions);
  };
  showWorksheetReview = function () {
    $questionsBreadcrumb.removeClass('breadcrumb--active');
    $reviewBreadcrumb.removeClass('breadcrumb--active').addClass('breadcrumb--active');
    $review.show();
    $questions.hide();
    $questionsButton.show();
    $saveWorksheetButton.hide();
    return $body.scrollTop(0);
  };
  showWorksheetQuestions = function (event) {
    event.preventDefault();
    $reviewBreadcrumb.removeClass('breadcrumb--active');
    $questionsBreadcrumb.removeClass('breadcrumb--active').addClass('breadcrumb--active');
    $questions.show();
    $review.hide();
    $questionsButton.hide();
    $saveWorksheetButton.show();
    sizeTableTextareas();
    return $body.scrollTop(0);
  };
  sizeTableTextareas = function () {
    return autosize.update($('.table-question textarea'));
  };
  updateFilename = function () {
    var filename;
    filename = $('#worksheet_pdf').val().split('\\').pop();
    return $('label.file').text(filename);
  };
  setUpWorksheetWizard();
  setUpWorksheetTable();
  return $('#worksheet_pdf').change(updateFilename);
});