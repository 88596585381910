import moment from 'moment';
import Highcharts from 'highcharts';
import '../../../plugins/bwelModal'
import '../../../components/charts/default';
import FormHelpers from '../../../components/forms/formHelpers'

$(function () {
  const $activityDaysButtons = $('input[name=activityDays]');
  const $activityTypeButtons = $('input[name=activityType]');
  const $chart = $('.js-activity-chart');
  const $checkedActivityTypeButton = function () {
    return $('input[name=activityType]:checked');
  };
  const updateValues = function (data) {
    $('.activity--activity .activity__main-action').html(data.weekly_activity_total);
    $('.activity--activity .todays_active_minutes').html(data.todays_active_minutes);
    $('.activity--activity .last_activity_at').html(data.last_activity_at);
  };
  const series = {
    name: '',
    data: []
  };
  const initChart = function (activityType) {
    const participantId = $chart.data('participant_id');
    const days = $('input[name=activityDays]:checked').data('days') === '' ? 1000 : $('input[name=activityDays]:checked').data('days');
    const options = {
      chart: {
        renderTo: $chart[0],
        type: 'column'
      },
      yAxis: {
        title: {
          text: "" + activityType
        }
      },
      xAxis: {
        title: {
          text: 'Date'
        }
      },
      series: [],
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          return ("<div class='tooltip-x'>" + (moment(this.point.x).format('MMM DD, YYYY')) + "</div>") + ("<div class='tooltip-x'>" + this.point.name + "</div>") + ("<div class='tooltip-y'>" + this.point.y + " " + activityType + "</div>");
        }
      }
    };

    $.ajax({
      url: '/activities',
      type: 'GET',
      async: true,
      dataType: 'json',
      data: {
        args: {
          participant_id: participantId,
          activity_type: activityType,
          days: days
        }
      },
      success: function (activities) {
        createChart(options, activityType, activities);
      }
    });
  };

  const createChart = (options, type, activities) => {
    const seriesMapper = type === 'steps' ? stepsSeries : nonStepSeries;
    series.data = _.compact(_.map(activities, seriesMapper));
    options.series.push(series);
    Highcharts.chart(options);
  };

  const nonStepSeries = (activity) => {
    if (activity.minutes != null) {
      return {
        x: Date.parse(moment(activity.date_active)),
        y: activity.minutes,
        name: activity.name
      };
    }
  };

  const stepsSeries = (activity) => {
    if (activity.steps != null) {
      return {
        x: Date.parse(moment(activity.date_active)),
        y: activity.steps,
        name: "Steps"
      };
    }
  };

  $activityTypeButtons.on('click', () => {
    var activityType;
    activityType = $checkedActivityTypeButton().val();
    initChart(activityType);
  });

  $activityDaysButtons.on('click', (event) => {
    const activityType = $checkedActivityTypeButton().val();
    const numberOfDays = event.target.id
    initChart(activityType, numberOfDays);
  });

  $('.js-open-activity-modal').on('click', () => {
    $('.js-activity-modal').bwelModal('open');
  });

  $('.js-activity-form').on('ajax:success', function (data, xhr, status) {
    FormHelpers.handleSuccess(this, 'Activity');
    initChart('minutes');
    updateValues(data);
  });

  $('.js-activity-form').on('ajax:error', function (xhr, status) {
    return FormHelpers.handleErrors(this, 'activity', status.responseJSON);
  });
  if ($chart.length > 0) { $checkedActivityTypeButton().click() }
});
