$(() => {
  return $.fn.clickToggle = function (func1, func2) {
    var funcs;
    funcs = [func1, func2];
    this.data('toggleclicked', 0);
    this.click(function () {
      var data, tc;
      data = $(this).data();
      tc = data.toggleclicked;
      $.proxy(funcs[tc], this)();
      return data.toggleclicked = (tc + 1) % 2;
    });
    return this;
  };
});
