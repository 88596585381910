import moment from 'moment';
import Highcharts from 'highcharts';
import '../../../plugins/bwelModal'
import '../../../plugins/bwelStatusMessage'
//import '../../../components/charts/default';
//import FormHelpers from '../../../components/forms/formHelpers'

let initProteinChart, proteinDaysButtons, showProteinSuccessMessage, populateProteinChart, closeProteinModal, deleteProtein;

$(function () {
  if ($('.js-protein-chart').length) {
    const chart = $('.js-protein-chart');
    const participantId = chart.data('participant_id');
    proteinDaysButtons = $('input[name=proteinDays]');

    // Open Protein Modal Form
    $(document).on('click', '.js-open-protein-modal', function() {
      $('.js-protein-modal').bwelModal('open');
      $('select#protein_points').val('');

      let newDate = new Date();
      let newDateMonth = newDate.getMonth() + 1;
      let newDateString = newDateMonth + '/' + newDate.getDate() + '/' + newDate.getFullYear();
      $('input#protein_date_consumed')[0].value = newDateString
    });

    // Close Protein Modal Form
    $(document).on('click', '.js-close-protein-modal', function() {
      $('select#protein_points').val('');
      $('.error').remove();
      $('.error_notification').html('');
      $('.js-protein-modal').bwelModal('close');
    });

    const initProteinChart = (proteins) => {
      var options = {
        chart: { renderTo: chart[0], type: 'column' },
        plotOptions: {
          series: {
            turboThreshold: 5000,
            cursor: 'pointer',
            point: {
              events: {
                click: function(event) {
                  deleteProtein(event.point.id);
                }
              }
            }
          }
        },
        yAxis: { title: { text: 'Checks' } },
        xAxis: { title: { text: 'Date' } },
        series: [],
        legend: {
          enabled: false
        }
      };
      var series = { name: '', data: [] }
      series.data = _.map(proteins, function (protein) {
         return {
           x: Date.parse(moment(protein.date_consumed)),
           y: parseInt(protein.points),
           id: protein.id
         };
      });
      options.series.push(series);
      Highcharts.chart(options);
    }

    window.showProteinSuccessMessage = showProteinSuccessMessage = () => {
      $('.js-status').bwelStatusMessage('open', "Protein was logged successfully!");
    }

    window.closeProteinModal = closeProteinModal = () =>  {
      $('.js-protein-modal').bwelModal('close');
    }

    window.populateProteinChart = populateProteinChart = (participantId) => {
      var days = $('input[name=proteinDays]:checked').data('days') === '' ? 1000 : $('input[name=proteinDays]:checked').data('days');
      $.ajax({
        url: '/proteins',
        type: 'get',
        datatype: 'json',
        async: true,
        data: {
          participant_id: participantId,
          days: days
        },
        success: function(proteins) {
          initProteinChart(proteins);
        }
      });
    }

    deleteProtein = (id) => {
      $('.js-delete-single-protein-modal').bwelModal('open');
      $('a.button.text-small.js-delete-single-protein').on('click', () => {
        if (id) {
          $.ajax({
            url: "/proteins/" + id,
            type: 'delete',
            dataType: 'json',
            success: function(proteins) {
              initProteinChart(proteins);
              $('.js-delete-single-protein-modal').bwelModal('close');
            }
          });
        }
      });
    };

    populateProteinChart(participantId);
  }

  $('input[name=proteinDays]').on('click', (event) => {
    var participantId = $('.js-protein-chart').data('participant_id');
    populateProteinChart(participantId);
  });

});
