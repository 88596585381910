import FormHelpers from '../../../components/forms/formHelpers';
import moment from 'moment'

let $messageForm, insertNewChildRow, insertNewParentRow, messageTableRead, messageTableReply, updateTable, recipientId, recipientFullName;

$(() => {
  $messageForm = $('.js-message-form');

  var messageTableRead = function() {
    $('.js-read-message').on('click', function(e) {
     e.preventDefault();
     let $row = $(this);
     let id = $(this).data('parentMessageId');

     if ( $(this).hasClass('new-message') ) {
       $.ajax({
         url: '/messages/' + id + '/read',
         type: 'PUT',
         async: true,
         dataType: 'json',
         contentType: 'application/json',
         data: JSON.stringify({ read_by_recipient: true }),
         complete: function (xhr, status) {
           if (xhr.status === 201 || xhr.status === 200) {
             $row.removeClass('new-message');
           }
         }
       });
     }
    });
  }

  $('tr.parent-message').on('click', (event) => {
    let parentMessageId = event.currentTarget.dataset.parentMessageId
    let childMessages = Array.from($('tr.child-message')).filter(childMessage => childMessage.classList.value.includes(`child-message-of-${parentMessageId}`))
    childMessages.forEach(childMessage => childMessage.style.display === 'table-row' ? childMessage.style.display = 'none' : childMessage.style.display = 'table-row')
  })

  messageTableReply = function () {
    let $parentIdInput, $recipientSelect, parentId;
    $('.js-message-reply').click((event) => {
      event.preventDefault()
      $('.js-messages-modal').bwelModal('open');
      recipientId = event.currentTarget.dataset.recipientId;
      parentId = event.currentTarget.dataset.parentId;
      $parentIdInput = $messageForm.find("input[name='message[parent_id]']");
      $recipientSelect = $messageForm.find("select[name='message[recipient_id]']");
      $('.js-submit-message').data('label', 'Reply Send');
      $('.js-clear-new-message-form').data('label', 'Reply Cancel');
      $parentIdInput.val(parentId);
      $recipientSelect.val(recipientId).prop("disabled", true);
    });
  };

  $('.js-message-table').dataTable({
    sorting: false,
    drawCallback: function () {
      messageTableRead();
      messageTableReply();
    }
  });

  $('.js-open-messages-modal').on('click', () => {
    let $newMessageButton;
    $('.js-messages-modal').bwelModal('open');
    $newMessageButton = $(this);
  });

  $('.js-new-message-to-participant').on('click', () => {
    let $newMessageButton, $recipientSelect;
    $newMessageButton = $(this);
    recipientId = location.pathname.split('/')[2]
    recipientFullName = $('h1.page-heading')[0].innerText
    $('#msg-recipient').empty()
    $('div.modal.modal--medium').prepend(`<h2 id="msg-recipient">Recipient: ${recipientFullName}</h2>`)
  });

  $('.js-clear-new-message-form').on('click', () => {
    let $parentIdInput;
    $messageForm[0].reset();
    $parentIdInput = $messageForm.find("input[name='message[parent_id]']");
    $parentIdInput.val("");
    $messageForm.find(':disabled').prop("disabled", false);
  });

  $('.js-submit-message').on('click', (event) => {
    event.preventDefault()
    const token = $('meta[name=csrf-token]').attr('content');
    const messageObject = {
      message: {
        sender_id: $messageForm.find("input[name='message[sender_id]']").val(),
        recipient_id: recipientId || $('select#message_recipient_id').val(),
        recipient_full_name: recipientFullName,
        parent_id: $messageForm.find("input[name='message[parent_id]']").val(),
        content: $('textarea[id=message_content]').val()
      }
    }

    $.ajax({
      url: "/messages",
      type: 'POST',
      async: true,
      dataType: 'json',
      data: messageObject,
      headers: {
        'X-CSRF-Token': token
      },
      complete: function (xhr, status) {
        if (xhr.status === 201 || xhr.status === 200) {
          updateTable(xhr.responseJSON);
          $messageForm[0].reset();
          $messageForm.find("input[name='message[parent_id]']").val("");
          $('.js-messages-modal').bwelModal('close');
        } else {
          FormHelpers.handleErrors($messageForm[0], 'message', xhr.responseJSON);
        }
      }
    });
    $(this).prop('disabled', false);
  });

  updateTable = function (messageJson) {
    if (messageJson.parent_id === null) {
      insertNewParentRow(messageJson);
    } else {
      insertNewChildRow(messageJson);
    }
  };

  insertNewParentRow = function (messageJson) {
    let colActions, colContent, colDate, colSender, newRow, replyLink, row;
    replyLink = "<a class='text-small js-open-messages-modal js-message-reply' data-recipient-id='" + messageJson.recipient_id + "' data-parent-id='" + messageJson.parent_id + "' href='#'>Reply</a>";
    row = $('<tr class="parent-message js-read-message"></tr>');
    colSender = $("<td>" + messageJson.sender.first_name + " " + messageJson.sender.last_name + "</td>");
    colContent = $("<td><span class='message-content'>" + messageJson.content + "</span></td>");
    colDate = $("<td>" + (moment(messageJson.updated_at).format(HARVARD.config.dateFormat)) + "</td>");
    colActions = $("<td>" + replyLink + "</td>");
    newRow = row.append(colSender, colContent, colDate, colActions);
    newRow.prependTo('.js-message-table:first');
  };

  insertNewChildRow = function (messageJson) {
    let childRowClass, colActions, colContent, colDate, colSender, lastChildRow, newRow, row;
    childRowClass = "child-message-of-" + messageJson.parent_id;
    row = $("<tr class='child-message js-read-message " + childRowClass + "'></tr>");
    colSender = $("<td>" + messageJson.sender.first_name + " " + messageJson.sender.last_name + "</td>");
    colContent = $("<td><span class='message-content'>" + messageJson.content + "</span></td>");
    colDate = $("<td>" + (moment(messageJson.updated_at).format(HARVARD.config.dateFormat)) + "</td>");
    colActions = $("<td></td>");
    newRow = row.append(colSender, colContent, colDate, colActions);
    lastChildRow = '.' + childRowClass + ':last';
    newRow.insertAfter(lastChildRow);
  };
});
