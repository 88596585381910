import '../../plugins/bwelStatusMessage'

$(() => {
  $('a.js-send-notification').click(function () {
    var appointment_ids;
    appointment_ids = $("input#appointment_id:checked").map(function () {
      return $(this).val();
    }).get();

    $.ajax({
      headers: {
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
      },
      url: "/appointments/send_notification",
      type: "POST",
      dataType: "script",
      data: {
        ids: appointment_ids
      }
    });
  });

  $('a.js-delete-appointment').on('click', (e) => {
    var appointmentId;
    if (confirm('Are you sure?')) {
      appointmentId = $(e.target).data('appointmentId');
      $.ajax({
        headers: {
          'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
        },
        url: "/appointments/" + appointmentId,
        dataType: 'script',
        type: 'DELETE'
      }).catch(response => {
        console.log('Appointment delete error: ', response.reponseStatus)
        $('.js-status').bwelStatusMessage('open', 'JS: Appointment not found. Unable to delete.');
      })
    }
  });

  $('#repeat-radio-buttons').click(function () {
    var selectedValue = $('input[name="appointment[repeat]"]:checked').val();
    if (selectedValue !== 'No') {
      $('#appointment_stay_on_form').prop('checked', true);
    } else {
      $('#appointment_stay_on_form').prop('checked', false);
    }
  });
});