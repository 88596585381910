// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


global.jQuery, global.$ = require('jquery');
require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')
require('moment')
require('jquery-datetimepicker')
require('jquery-validation')
require('bloodhound-js')
require('typeahead.js')
require('autosize')
require('datatables.net')
require('datatables.net-dt')
require('tinymce')
require('tinymce/themes/silver/theme')
require('tinymce/icons/default/icons.js')
require('tinymce/skins/ui/oxide/skin.min.css')
require('highcharts')

// appointments
require('components/appointments/appointments')

// calendar
require('components/calendar/copy_calendar_subscription_url')
require('components/calendar/fullcalendar')
require('components/calendar/schedule')

// charts
// require('components/charts/charts')
// require('components/charts/default')

// datetimepicker
require('components/datetimepicker/datetimepicker')

// foods
// require('components/foods/food_helpers')
// require('components/foods/food_options')
// require('components/foods/search')
// require('components/foods/sourceHelper')

// forms
require('components/forms/formHelpers')
require('components/forms/forms')

// header
require('components/header/header')

// nav
require('components/nav/nav')

// tables
require('components/tables/datatables')
require('components/tables/default')

// weights
// require('components/weights/weight_options')

// worksheets
require('components/worksheets/questions')
require('components/worksheets/worksheet_modal')

// config
require('config/config')

// pages/coach/participants
require('pages/coach/participants/show')

// pages/participants
require('pages/participants/index/activity')
// require('pages/participants/index/food')
require('pages/participants/index/message')
// require('pages/participants/index/weight')
require('pages/participants/index/protein')

// plugins
require('plugins/bwelAccordion')
require('plugins/bwelClickToggle')
require('plugins/bwelLabel')
require('plugins/bwelModal')
// require('plugins/bwelRepeatPicker')
// require('plugins/bwelStatusMessage')

$(function () {
  // always pass csrf tokens on ajax calls
  $.ajaxSetup({
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
  });
});
