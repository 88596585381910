import '../../plugins/bwelModal'
import '../../plugins/bwelStatusMessage'

const FormHelpers = {
  handleSuccess: function (form, objectType) {
    $(form).parents('.modal-wrapper').bwelModal('close');
    form.reset();
    this.clearErrors(form);
    $('.js-status').bwelStatusMessage('open', objectType + " was logged successfully!");
  },
  handleUnauthorized: function (form, objectType) {
    $(form).parents('.modal-wrapper').bwelModal('close');
    form.reset();
    this.clearErrors(form);
    $('.js-status').bwelStatusMessage('open', "You do not have permission to modify " + objectType + "!");
  },
  handleErrors: function (form, formName, errors) {
    this.clearErrors(form);
    this._addErrors(form, formName, errors);
  },
  clearErrors: function (form) {
    $(form).find("input").attr('placeholder', '').parents('.field').removeClass('input--error');
  },
  _addErrors: function (form, formName, errors) {
    var key, results, value;
    results = [];
    for (key in errors) {
      value = errors[key];
      if (!key.match(/.+\[.+\]/)) {
        key = formName + "[" + key + "]";
      }
      if (value) {
        results.push($(form).find("[name='" + key + "']").val('').attr("placeholder", "" + value).parents('.field').addClass('input--error'));
      } else {
        results.push($(form).find("[name='" + key + "']").parents('.field').removeClass('input--error'));
      }
    }
    return results;
  }
};

export default FormHelpers;