import FormHelpers from '../forms/formHelpers';
import '../forms/formHelpers';
import '../../jsgems/fullcalendar291/fullcalendar';
import _ from 'lodash';
import '../../plugins/bwelModal'
import moment from 'moment';

$(function () {
  var addDeleteButtonOnEvents, appointmentTitle, createAppointment, deleteEventForm, removeAllEvents, removeSingleEvent;
  $(".js-close-delete-event-modal").on('click', function () {
    $(".js-delete-recurring-event-modal").bwelModal('close');
    return $(".js-delete-single-event-modal").bwelModal('close');
  });
  removeAllEvents = function (event) {
    return $.ajax({
      url: "/appointments/" + event.id,
      type: "DELETE",
      dataType: 'json',
      complete: function (xhr, status) {
        if (xhr.status === 204) {
          $('.js-fullcalendar').fullCalendar('removeEvents', [event._id]);
          $('.js-delete-recurring-event-modal').bwelModal('close');
        }
      }
    });
  };
  removeSingleEvent = function (event) {
    return $.ajax({
      url: "/appointment/" + event.id + "/add_exception",
      dataType: 'json',
      type: "POST",
      data: {
        exception_datetime: event.start.format('YYYY-MM-DD HH:mm:ss')
      },
      complete: function (xhr, status) {
        if (xhr.status === 204) {
          $('.js-fullcalendar').fullCalendar('removeEvents', [event._id]);
          $('.js-delete-recurring-event-modal').bwelModal('close');
          $('.js-delete-single-event-modal').bwelModal('close');
        }
      }
    });
  };
  createAppointment = function (appointment) {
    var duration;
    duration = appointment.duration * 1000;
    return _.map(appointment.occurrences, function (startTimeString) {
      var startTime;
      startTime = Date.parse(startTimeString);
      return {
        start: startTime,
        end: startTime + duration,
        title: appointmentTitle(appointment),
        color: appointment.color,
        id: appointment.id,
        repeats: appointment.occurrences.length > 1 ? true : false
      };
    });
  };
  appointmentTitle = function (appointment) {
    if (appointment.week) {
      return appointment.title + "\n[Wk " + appointment.week + "]";
    } else {
      return appointment.title;
    }
  };
  addDeleteButtonOnEvents = function () {
    var $fcEvent;
    $fcEvent = $(document).find('.fc-event');
    return $fcEvent.each(function () {
      return $('.fc-content', this).append('<i class="fc-event-delete fa fa-times"></i>');
    });
  };
  return $('.js-fullcalendar').fullCalendar({
    defaultView: 'basicWeek',
    // defaultView: 'month',
    header: {
      left: 'prev',
      center: 'title',
      right: 'next'
    },
    buttonIcons: {
      prev: 'left-single-arrow',
      next: 'circle-triangle-e'
    },
    columnFormat: 'ddd D',
    theme: true,
    businessHours: {
      start: '09:00',
      end: '17:00',
      dow: [0, 1, 2, 3, 4, 5, 6]
    },
    displayEventEnd: true,
    timezone: 'local',
    eventAfterAllRender: function (view) {
      return addDeleteButtonOnEvents();
    },
    eventRender: function (event, element) {
      return element.attr("event-id", event.id);
    },
    events: function (start, end, timezone, callback) {
      var participantId = $('#scheduleForm').data('participantId');
      var args = {};
      if (start != null) {
        args.start = moment(start).format("YYYY-MM-DD");
        args.end = moment(end).format("YYYY-MM-DD");
      }
      if (participantId != null) {
        args.participant_id = participantId;
      }
      return $.ajax({
        url: '/appointments',
        dataType: 'json',
        data: args,
        success: function (data) {
          var appointments;
          appointments = _.flatten(_.map(data, function (appointment) {
            return createAppointment(appointment);
          }));
          return callback(appointments);
        }
      });
    },
    eventClick: function (event) {
      var bind_event;
      bind_event = event;
      if (bind_event.repeats) {
        return $('.js-delete-recurring-event-modal').bwelModal('open', function () {
          $('.js-delete-event').off('click');
          return $('.js-delete-event').on('click', function (e) {
            deleteEventForm.validate({
              showErrors: function () {
                return false;
              },
              messages: {
                'event[delete]': {
                  required: 'Required'
                }
              },
              invalidHandler: function (event, validator) {
                var errors;
                errors = validator.errorMap;
                return FormHelpers.handleErrors(this, 'event', errors);
              },
              submitHandler: function () {
                var val;
                val = $('input[name="event[delete]"]:checked').val();
                if (val === 'all') {
                  removeAllEvents(bind_event);
                } else {
                  removeSingleEvent(bind_event);
                }
                e.preventDefault();
                return $('.js-delete-recurring-event-modal').bwelModal('close');
              }
            });
            return deleteEventForm.submit();
          });
        });
      } else {
        return $('.js-delete-single-event-modal').bwelModal('open', function () {
          $('.js-delete-single-event').off('click');
          return $('.js-delete-single-event').on('click', function () {
            removeAllEvents(bind_event);
            return $('.js-delete-single-event-modal').bwelModal('close');
          });
        });
      }
    }
  });
});
