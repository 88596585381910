import Highcharts from 'highcharts';

var formatThousands;
$(function () {
  formatThousands = function (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return Highcharts.setOptions({
    colors: ['#95D345'],
    title: false,
    global: {
      useUTC: false
    },
    credits: {
      enabled: false
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#ffffff',
      borderColor: '#ededee',
      borderRadius: 2,
      shadow: false
    },
    chart: {
      style: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '1rem',
        textTransform: 'uppercase'
      }
    },
    plotOptions: {
      column: {
        borderRadius: 5
      }
    },
    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,
      labels: {
        style: {
          color: '#A2A2A2'
        }
      },
      dateTimeLabelFormats: {
        millisecond: '%I:%M %p',
        second: '%H:%M %S',
        minute: '%I:%M %p',
        hour: '%I:%M %p',
        day: '%b %e',
        week: '%b %e',
        month: '%b %y',
        year: '%Y'
      },
      minTickInterval: 86400000
    },
    yAxis: {
      title: {
        enabled: true
      },
      min: 0,
      labels: {
        style: {
          color: '#A2A2A2'
        },
        formatter: function () {
          return formatThousands(this.value);
        }
      }
    }
  });
});

export default formatThousands;