var bind = function (fn, me) { return function () { return fn.apply(me, arguments); }; },
  slice = [].slice;

$(() => {
  const BwelStatusMessage = (() => {
    BwelStatusMessage.prototype.defaults = {
      classNames: {
        STATUS: '.js-status',
        CLOSE: '.js-status-close',
        DESCRIPTION: '.status__description'
      }
    };

    function BwelStatusMessage($el, options) {
      this.setMessage = bind(this.setMessage, this);
      this.close = bind(this.close, this);
      this.open = bind(this.open, this);
      var closeBtn;
      this.options = $.extend({}, this.defaults, options);
      this.$el = $el;
      closeBtn = this.$el.find(this.options.classNames.CLOSE);
      this.$el.hide();
      closeBtn.on('click', this.close);
    }

    BwelStatusMessage.prototype.open = function (message) {
      this.$el.slideDown('fast');
      this.setMessage(message);
      setTimeout(this.close, 10000); // auto close after 10 seconds
    };

    BwelStatusMessage.prototype.close = function () {
      this.$el.slideUp('fast');
    };

    BwelStatusMessage.prototype.setMessage = function (message) {
      return this.$el.find(this.options.classNames.DESCRIPTION).text(message);
    };

    return BwelStatusMessage;
  })();

  $.fn.extend({
    bwelStatusMessage: function () {
      var args, option;
      option = arguments[0], args = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      return this.each(function () {
        var $this, data;
        $this = $(this);
        data = $this.data('bwelStatusMessage');
        if (!data) {
          $this.data('bwelStatusMessage', (data = new BwelStatusMessage($this, option)));
        }
        if (typeof option === 'string') {
          return data[option].apply(data, args);
        }
      });
    }
  })
});
