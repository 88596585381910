import '../../plugins/bwelStatusMessage'

$(() => {
  return $('.js-subscription-button').click(function () {
    var textbox;
    textbox = $(this).parents('div').children('.js-subscription-url');
    $(textbox).show();
    textbox.select();
    document.execCommand('copy');
    $(textbox).hide();
    $('.js-status').bwelStatusMessage('open', 'Successfully copied URL!');
    return false;
  });
});